import { Spin, Table, Card } from "antd";
import React, { useState, useEffect } from "react";
import API from "../../../util/api";
import utils from "./utils";

const OcupationalTrafficPayout = (props) => {
  const [occupationalTrafficPayout, setOccupationalTrafficPayout] = useState();
  const [filterCountries, setFilterCountries] = useState();
  const [filterIsActiveCommerce, setFilterIsActiveCommerce] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState();
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
   
    if(props.data && props.data.filter.countries[1] && props.data.filter.countries[1].hasOwnProperty("PAYOUT") && props.data.data.hasOwnProperty("PAYOUT") ){
      setOccupationalTrafficPayout(props.data.data.PAYOUT);
      setFilterCountries(props.data.filter.countries);
      setFilterIsActiveCommerce(props.data.filter.activeCommerce);
      createTable();
    } else if(props.data && props.data.filter.countries[1] && (!props.data.filter.countries[1].hasOwnProperty("PAYOUT") || !props.data.data.hasOwnProperty("PAYOUT"))){
      setLoading(true);
    } else if(props.data && !props.data.filter.countries[1]){
      setLoading(true);
    }
    
  }, [props.data])
  

  const createTable = () => {
    const data = JSON.parse(localStorage.getItem("OccupationalTraffic"));
    const payout = data.data.PAYOUT;
    const countries = data.filter.countries;
    const activeCommerce = data.filter.activeCommerce;
    // const payout = occupationalTrafficPayout;
    // const countries =  filterCountries
    // const activeCommerce = filterIsActiveCommerce
    const countriesKey = {};
    countries[1].PAYOUT.map(country => {
      countriesKey[country] = country;
    });
    let dataPayout = [];
    let key = 1;
    let methodsCountry = {};
    payout.map(commerce => {
      for (let nameCommerce in commerce) {
        for (let country in commerce[nameCommerce]) {
          for (let method in commerce[nameCommerce][country]) {
            methodsCountry[country] =commerce[nameCommerce][country][method];
          }
        }
        dataPayout.push({
          key: key,
          commerce: nameCommerce,
          active: String(activeCommerce[nameCommerce]),
          ...methodsCountry
        });
        key++;
        methodsCountry = {};
      }
    });
    console.log("[LOG] [datapayout]", dataPayout)
    setDataSource([...dataPayout]);

    // COLUMNAS
    let subColumns = [];
    for (let clave in countriesKey) {
        subColumns.push({
          title:  utils.utils.getNameCountry(clave),
          dataIndex: clave,
          key: clave,
          width: 100,
          render: (text, record) => { // Cambia '1' y 25 por los valores de la celda que deseas destacar
            const cellStyle = {
                'background':  utils.utils.colorProvider(text).background, 
                'padding': '8px', 
                'color': utils.utils.colorProvider(text).color, 
                'borderRadius': '30px',
                'boxShadow': '3px 3px 5px #454545'
              
              }
            return {
              children: <span 
              style={text ? cellStyle : null }>{text}</span>,
            };
          },
        });
    }

    setColumns([
      {
        title: "COMMERCE",
        dataIndex: "commerce",
        key: "commerce",
        fixed: "left",
        width: 200
      },
      {  title: "ACTIVE", dataIndex: "active",key: "active", width: 100, align: "center",
      render: (text, record) => { // Cambia '1' y 25 por los valores de la celda que deseas destacar
        const cellStyle = {
            'background':  utils.utils.colorActive(text).background, 
            'padding': '8px', 
            'color': utils.utils.colorActive(text).color, 
            'borderRadius': '30px',
            'boxShadow': '3px 3px 5px #454545'
          
          }
        return {
          children: <span 
          style={text ? cellStyle : null }>{text}</span>,
        };
      }, },
      {
        title: "COUNTRIES",
        children: subColumns
      }
    ]);
    setLoading(true);
  };

  return (
    <Card>
      <h2>PayOut</h2>
      {loading ? (
        <Table
          className="gx-table-responsive"
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 1000, y: 400 }}
          bordered
          size="middle"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"]
          }}
        />
      ) : (
        <div>
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
};

export default OcupationalTrafficPayout;
