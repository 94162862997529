import React, { Component } from "react";
import { Col, Row, Button, Input } from "antd";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import GrowthCard from "components/dashboard/CRM/GrowthCardMerchant";
import _ from "lodash";
import Auxiliary from "util/Auxiliary";
import API from "../../../util/api";

const db = firebase.firestore();
let query;
let queryWeek;
let dataList = [];

export class skinsbackOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: " Skinsback Validated",
      filename: `Skinsback Validated ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      dataSkinsback: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        TotalSinCommission: 0
      },
      dataSkinsbackWeek: {
        dataWeek: "",
        sumWeek: 0,
        countdocsWeek: 0
      },
      firstTime: true,
      inputPreaidCode: "",
      defaultValueRangeFilter: [

        moment().tz("America/Santiago").subtract(1, "days"),
        moment().tz("America/Santiago")
      ]
    };
  }


  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps",nextProps,"country_code",localStorage.getItem("country_code"));
    /* this.componentDidMount(); */
  }

  componentDidMount = () => {

    moment.tz.setDefault(this.state.timeZone);

    let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
    let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();

     let firstDayWeek = moment.tz(this.state.timeZone).startOf("isoWeek").toDate();
     let lastDayWeek = moment.tz(this.state.timeZone).endOf("isoWeek").toDate();

    let now = moment().tz(this.state.timeZone).toDate();
    let yesterday = moment().tz(this.state.timeZone).subtract(1, "day").startOf('day').toDate();

    this.updateQuery(yesterday, now);
    if(this.props.dataUser.typeUser==="merchant"){this.getSkinsbackOKInfo(firstDayWeek, lastDayWeek,firstDay,lastDay) }

    // CALCULO EL MES ACTUAL
    let mes = moment.tz(this.state.timeZone).format("MMMM");
    this.setState({
      mes: mes
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      console.log("componentDidUpdate");
      const merchant = localStorage.getItem("merchant_code");
      moment.tz.setDefault(this.state.timeZone);
      let firstDayWeek = moment.tz(this.state.timeZone).startOf("isoWeek").toDate();
      let lastDayWeek = moment.tz(this.state.timeZone).endOf("isoWeek").toDate();
      let now = moment().tz(this.state.timeZone).toDate();
      let yesterday = moment().tz(this.state.timeZone).subtract(1, "day").startOf('day').toDate();
      let firstDay = moment.tz(this.state.timeZone).startOf("month").toDate();
      let lastDay = moment.tz(this.state.timeZone).endOf("month").toDate();
      // CALCULO EL MES ACTUAL
      let mes = moment.tz(this.state.timeZone).format("MMMM");
      this.setState({
        mes: mes
      });


      this.updateQuery(yesterday, now);
      if(this.props.dataUser.typeUser==="merchant"){this.getSkinsbackOKInfo(firstDayWeek, lastDayWeek,firstDay,lastDay) }

      this.setState({
        filename: `skinsback validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }

    getSkinsbackOKInfo = async (firstDayWeek,lastDayWeek,firstDay,lastDay) => {

      let commissionesBd = this.props.comissionData
        ? this.props.comissionData
        : JSON.parse(localStorage.getItem("comisiones"));
      commissionesBd = commissionesBd.filter(
        item => item.merchant === this.props.merchant.code
      );
      commissionesBd = commissionesBd[0].commission.filter(
        item => item.countrie === this.props.country.code
      );


      /* let parametros = {
        "merchant": this.props.merchant.code,
        "firstDay": firstDayWeek,
        "lastDay": lastDayWeek,
        "comission": commissionesBd[0],
        "country": localStorage.getItem("country_code"),
        "code": 0,
        "payMethod":"bankskinsback",

      }
      console.log("parametros",parametros)
 */
      let parametros = {
        "merchant": this.props.merchant.code,
        "firstDayWeek": firstDayWeek,
        "lastDayWeek": lastDayWeek,
        "firstDay": firstDay,
        "lastDay": lastDay,
        "comission": commissionesBd[0],
        "country": this.props.country.code,
        "code": 0,
      }
      console.log("parametros Week",parametros )

     /*  await API.utils.getBankskinsbacksForParamsOK(parametros).then(
      resp =>{
        console.log(resp)
        this.setState({
          dataSkinsbackWeek: resp.dataSkinsbackWeek,
          dataSkinsback: resp.dataSkinsback
        });
      }); */

      await API.utils.getSkinsbackOKInfo(parametros).then(
        resp =>{


          console.log("RESPUESTA getSkinsbackOKInfo",resp)

          this.setState({
            dataSkinsbackWeek: resp[0].dataWeek,
            dataSkinsback: resp[0].data
          });
        });
    }

  updateQuery = async (firstDay, lastDay,merchant) => {

    dataList = [];
    let commissionesBd = this.props.comissionData
      ? this.props.comissionData
      : JSON.parse(localStorage.getItem("comisiones"));
    commissionesBd = commissionesBd.filter(
      item => item.merchant === this.props.merchant.code
    );
    commissionesBd = commissionesBd[0].commission.filter(
      item => item.countrie === this.props.country.code
    );

    /************************************************************************************************ */

    let parametros = {
      "merchant": this.props.merchant.code,
      "firstDay": firstDay,
      "lastDay": lastDay,
      "comission": commissionesBd[0],
      "country": localStorage.getItem("country_code"),
      "code": 0,
      "payMethod":"bankskinsback",

    }
    console.log("parametros", parametros)

   
    await API.utils.getSkinsBackForParamsOK(parametros).then(cons => {
      console.log("getSkinsBackForParamsOK", cons)
      this.setState({
        dataList: cons
      })

      cons.sort(function (x, y) {
        return x.dataSkinsback - y.dataSkinsback;
      })
      console.log("data ordenada ", cons);

    });
    //console.log(this.state.dataList.sort((a, b) => new Date(a.fechas).getTime() > new Date(b.fechas).getTime()));
  };
  update = async (e) => {
    await this.componentDidUpdate(this.state);
  }

  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Operation Code: <b>{record.operationCode}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>
        {this.props.dataUser.typeUser === "admin" || this.props.dataUser.typeUser === "soporteAdmin" ? (

          <div>
            {record.adminCallBack ?
              (<p>
                Admin Callback:<b>{record.adminCallBack}</b>
              </p>) : ""
            }
          </div>
        ) : (
            <> </>
          )}
        {this.props.dataUser.typeUser !== "merchant" ? (
          <div>
            {record.collection ?
              (<p>
                Agregator:<b>{record.collection}</b>
              </p>) : ""
            }
          </div>
        ) : (
            <> </>
          )}
      </div>
    );
    let view;
    if (this.props.dataUser.typeUser === "merchant") {
      view = (
        <Row>
          {/* <Col xl={8} lg={24} md={8} sm={24} xs={24}>
            <GrowthCard
            //  trafficData={_.takeRight(this.state.dataSkinsback.data, 5)}
              avg={parseFloat(this.state.dataSkinsbackWeek.sumdocsWeek).toFixed(0)}
              title="Transaction Amount"
              month={this.state.mes}
              suma={this.state.dataSkinsback.sum}
              prefix={"$"}
            />
          </Col>
          <Col xl={8} lg={12} md={8} sm={24} xs={24}>
            <GrowthCard
              title="Total Transactions"
              month={this.state.mes}
              suma={this.state.dataSkinsback.count}
              avg={this.state.dataSkinsbackWeek.countdocsWeek}
            />
          </Col>
          <Col xl={8} lg={12} md={8} sm={24} xs={24}>
            <GrowthCard
              param="0"
              title="Total without commision"
              month={this.state.mes}
              suma={this.state.dataSkinsback.TotalSinCommission}
              prefix={"$"}
            />
          </Col> */}
          <Col span={24}>
            <Dynamic
              defaultValue={this.state.defaultValueRangeFilter}
              updateQuery={this.updateQuery}
              filename={this.state.filename}
              title={this.state.title}
              dataList={this.state.dataList}
              // columns={this.columns}
              expandedRowRender={this.expandedRowRender}
              getData={this.getData}
              onTableChange={this.onTableChange}
              timeZone={this.state.timeZone}
              merchant={this.props.merchant}
              typeUser={this.props.dataUser.typeUser}
            // onTimeZoneChange={e => this.onTimeZoneChange(e)}
            />
          </Col>
        </Row>
      );

    }
    else {
      view = (<Row>
        <Col span={24}>
          <Dynamic
            defaultValue={this.state.defaultValueRangeFilter}
            updateQuery={this.updateQuery}
            user={this.props.authUser}
            update={this.update}
            filename={this.state.filename}
            title={this.state.title}
            dataList={this.state.dataList}
            expandedRowRender={this.expandedRowRender}
            getData={this.getData}
            onTableChange={this.onTableChange}
            merchant={this.props.merchant}
            typeUser={this.props.dataUser.typeUser}
          />
        </Col>
       
      </Row>);
    }
    return (
      <Auxiliary>
        {view}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant, comissionData, country, dataUser } = settings;
  return { authUser, merchant, comissionData, country, dataUser };
};
export default connect(mapStateToProps)(skinsbackOk);
