import React, { Component } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebase";
import Dynamic from "./Dynamic";
import moment from "moment-timezone";
import GrowthCard from "components/dashboard/CRM/GrowthCardMerchant";
import _ from "lodash";

const db = firebase.firestore();
let query;
// let queryWeek;
let dataList = [];

export class prtOk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: {
        order: "descend",
        columnKey: "dateRequest"
      },
      title: "PCT Validated",
      filename: `PCT Validated ${this.props.merchant.name}`,
      timeZone: "America/Santiago",
      indicadores: {
        dolar: {
          valor: ""
        }
      },
      date: "",
      dataPct: {
        data: "",
        sum: 0,
        count: 0,
        commission: 0,
        ivaCommission: 0,
        TotalSinCommission: 0
      },
      dataPctWeek: {
        dataWeek: "",
        sumWeek: 0,
        countdocsWeek: 0
      },
      firstTime: true
    };
  }

  componentDidMount = () => {
    moment.tz.setDefault(this.state.timeZone);
   /*  let today = moment.tz(new Date(), this.state.timeZone).toDate();
    let yesterday = moment(new Date(), this.state.timeZone).add(-1, 'days').toDate(); */

    let firstDay = moment
      .tz(this.state.timeZone)
      .startOf("month")
      .toDate();
    let lastDay = moment
      .tz(this.state.timeZone)
      .endOf("month")
      .toDate();

    let firstDayWeek = moment
      .tz(this.state.timeZone)
      .startOf("isoWeek")
      .toDate();

    let lastDayWeek = moment
      .tz(this.state.timeZone)
      .endOf("isoWeek")
      .toDate();

    this.getData(firstDay, lastDay, firstDayWeek, lastDayWeek);

    // CALCULO EL MES ACTUAL
    let mes = moment.tz(this.state.timeZone).format("MMMM");
    this.setState({
      mes: mes
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.merchant !== prevProps.merchant) {
      console.log("componentDidUpdate", "=>", "componentDidUpdate");
      moment.tz.setDefault(this.state.timeZone);
      const merchant = localStorage.getItem("merchant_code");
      let firstDay = moment
        .tz(this.state.timeZone)
        .startOf("month")
        .toDate();
      let lastDay = moment
        .tz(this.state.timeZone)
        .endOf("month")
        .toDate();

      // let firstDayWeek = moment
      //   .tz(this.state.timeZone)
      //   .startOf("isoWeek")
      //   .toDate();

      // let lastDayWeek = moment
      //   .tz(this.state.timeZone)
      //   .endOf("isoWeek")
      //   .toDate();

      // CALCULO EL MES ACTUAL
      let mes = moment.tz(this.state.timeZone).format("MMMM");
      this.setState({
        mes: mes
      });
      query = db
        .collection("mxBbvaPcts")
        .where("code", "==", 0)
        .where("merchantId", "==", merchant)
        .where("dateRequest", ">=", new Date(Number(firstDay)))
        .where("dateRequest", "<=", new Date(Number(lastDay)));

      this.updateQuery();

      this.setState({
        filename: `Pct Validated ${localStorage.getItem("merchant_name")}`
      });
    }
  }

  getData = (firstDay, lastDay, firstDayWeek = null, lastDayWeek = null) => {
    query = db
      .collection("mxBbvaPcts")
      .where("code", "==", 0)
      .where("merchantId", "==", this.props.merchant.code)
      .where("dateRequest", ">=", new Date(Number(firstDay)))
      .where("dateRequest", "<=", new Date(Number(lastDay)));

    this.updateQuery();
  };

  updateQuery = () => {

  let factor=0;

  /****************************************COMISIONES************************************ */

  let commissionesBd=this.props.comissionData?this.props.comissionData: JSON.parse(localStorage.getItem("comisiones"));
  commissionesBd =  commissionesBd.filter(item=> item.merchant===this.props.merchant.code)
  commissionesBd = commissionesBd[0].commission.filter(item=> item.countrie===this.props.country.code)
  factor =commissionesBd[0].pctCobro;
/************************************************************************************************ */
    dataList = [];
    query
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          this.setState({
            dataList
          });
          return;
        }
        let resultadosOk = [];
        snapshot.forEach(doc => {
          if (this.state.firstTime) {
            resultadosOk.push({
              date: moment(doc.data().dateRequest.toDate()).toLocaleString(
                "es-CL",
                {
                  timeZone: this.state.timeZone,
                  timeZoneName: "short"
                }
              ),
              amount: doc.data().amount
            });

            // OBJETO DE RESULTADO
            var docs = snapshot.docs.map(doc => doc.data());

            // SUMA TOTAL DEL MES

            let sum = _.sumBy(docs, item => Number(item.amount));
            let count = docs.length;

            // CALCULO COMISIONES
            //let factor;

            //factor = 0.045;

            let commission = Number(
              parseFloat(Number(sum) * factor).toFixed(2)
            );
            let ivaCommission = Number(
              parseFloat(commission * 0.19).toFixed(2)
            );

            if (isNaN(commission)) {
              commission = 0;
              ivaCommission = 0;
            }

            let TotalSinCommission = 0;
            TotalSinCommission = sum - commission - ivaCommission;

            let response;
            console.log(
              "TotalSinCommission " +
                TotalSinCommission +
                "count: " +
                count +
                "suma: " +
                sum +
                "commission " +
                commission +
                "ivaCommission " +
                ivaCommission
            );
            response = {
              sum,
              count,
              TotalSinCommission
            };

            this.setState({
              dataPct: response,
              firstTime: false
            });
          }

          let PctId = doc.id;
          let commision = Number(Number(doc.data().amount * 0.055).toFixed(0));
          let iva = Number((commision * 0.19).toFixed(0));

          dataList.push({
            ...doc.data(),
            PctId,
            commision,
            iva,
            totCommision: commision + iva,
            total: Number(doc.data().amount) - (commision + iva)
          });
        });

        this.setState({
          dataList
        });
        console.log("DATALIST", "=>", dataList);
      })
      .catch(console.log);
  };

  onTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  render() {
    this.expandedRowRender = record => (
      <div>
        <p>
          Operation Code: <b>{record.authorization}</b>
        </p>
        <p>
          Card Number: <b>{record.card_number}</b>
        </p>
        <p>
          Email: <b>{record.email}</b>
        </p>

        <p>
        bbvaId: <b>{record.bbvaId}</b>
        </p>
      </div>
    );

    switch (this.props.authUser) {
      case "latamerica@1xbet.com":
        return (
          <Row>
            {/* <Col xl={8} lg={24} md={8} sm={24} xs={24}>
              <GrowthCard
                avg={parseFloat(this.state.dataPctWeek.sumdocsWeek).toFixed(0)}
                title="Transaction Amount"
                month={this.state.mes}
                suma={this.state.dataPct.sum}
                prefix={"$"}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <GrowthCard
                title="Total Transactions"
                month={this.state.mes}
                suma={this.state.dataPct.count}
                avg={this.state.dataPctWeek.countdocsWeek}
              />
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <GrowthCard
                param="0"
                title="Total without commision"
                month={this.state.mes}
                suma={this.state.dataPct.TotalSinCommission}
                prefix={"$"}
              />
            </Col> */}
            <Col span={24}>
              <Dynamic
                filename={this.state.filename}
                title={this.state.title}
                dataList={this.state.dataList}
                // columns={this.columns}
                expandedRowRender={this.expandedRowRender}
                getData={this.getData}
                // onTableChange={this.onTableChange}
              />
            </Col>
          </Row>
        );
      // break;

      default:
        return (
          <Row>
            <Col span={24}>
              <Dynamic
                filename={this.state.filename}
                title={this.state.title}
                dataList={this.state.dataList}
                // columns={this.columns}
                expandedRowRender={this.expandedRowRender}
                getData={this.getData}
                merchant={this.props.merchant}
                // onTableChange={this.onTableChange}
              />
            </Col>
          </Row>
        );
    }
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { merchant,comissionData,country } = settings;
  return { authUser, merchant ,comissionData,country};
};
export default connect(mapStateToProps)(prtOk);
